<template>
  <div class="AccountForm">
    <b-form>
      <b-form-group id="input-group-1" class="AccountForm__avatar">
        <b-input-group>
          <div class="AccountForm__avatar-img">
            <img
              :src="form.avatar || defaultAvatar"
              alt="avatar-image"
              @click="handleOnclickImage"
            />
          </div>
          <label :ref="imageRef" for="input-image" class="AccountForm__avatar-label"
            ><b-button class="AccountForm__avatar-button" variant="light"
              >画像アップロード</b-button
            ></label
          >
          <input
            id="input-image"
            ref="upload-image"
            class="AccountForm__avatar-file"
            type="file"
            accept=".jpg,.jpeg,.png"
            @change="loadFile"
          />
          <span v-if="!!errorMessages.form.avatar" class="label-error"
            >{{ errorMessages.form.avatar }}
          </span>
        </b-input-group>
      </b-form-group>
      <b-form-group id="input-group-1" label="名前" label-for="input-1" class="required">
        <b-input-group>
          <b-form-input
            id="input-1"
            type="text"
            :value="form.name"
            :class="status($v.form.name)"
            :formatter="formatter"
            @change="setFormValue('name', $event)"
          />
          <b-input-group-prepend v-if="!!errorMessages.form.name" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-prepend>
        </b-input-group>
        <span v-if="!!errorMessages.form.name" class="label-error"
          >{{ errorMessages.form.name }}
        </span>
      </b-form-group>

      <b-form-group id="input-group-1" label="メールアドレス" label-for="input-2" class="required">
        <b-input-group>
          <b-form-input
            id="input-2"
            type="email"
            :value="form.email"
            :class="status($v.form.email)"
            :formatter="formatter"
            @change="setFormValue('email', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.email" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.email" class="label-error"
          >{{ errorMessages.form.email }}
        </span>
      </b-form-group>

      <b-form-group id="input-group-3" label="権限" label-for="input-4">
        <b-form-input id="input-4" type="text" :value="role_name" :disabled="true" />
      </b-form-group>

      <div class="AccountForm__button">
        <b-button variant="primary" @click="onUpdateInfo">
          <span>更新</span>
        </b-button>
      </div>

      <div class="AccountForm__button AccountForm__button-changepass">
        <b-button @click="showChangePasswordDialog">
          <span>パスワード変更</span>
        </b-button>
      </div>
    </b-form>
    <change-password-dialog :id="dialogChangePassId" :confirm-change-password="changePassword" />
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { MESSAGES } from '@/utils/messages'
import { mapGetters, mapActions } from 'vuex'
import pick from 'lodash-es/pick'
import { ALERT_TYPE, DEFAULT_IMAGE, MAX_FILE_SIZE, MAX_LENGTH } from '@/utils/constants'
import {
  fileImageToBase64,
  notFreeMail,
  validateFileSize,
  validateFileType,
} from '@/utils/function-utils'
import { userService } from '@/services/auth-service/user-service'
import get from 'lodash-es/get'
import ChangePasswordDialog from '@/components/modals/change-password-modal'
import { isHttpLink } from '@/utils/object-helpers'

export default {
  name: 'MTAccount',
  components: { ChangePasswordDialog },
  data() {
    return {
      dialogChangePassId: 'changepass-id',
      form: {
        name: '',
        email: '',
        avatar: '',
      },
      errorMessages: {
        form: {
          name: '',
          email: '',
          avatar: '',
        },
      },
      imageRef: 'image-ref',
      defaultAvatar: DEFAULT_IMAGE,
      allowFileTypes: ['png', 'jpg', 'jpeg'],
    }
  },
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email,
        // not end with free email
        notFreeMail: notFreeMail,
      },
    },
  },
  computed: {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('commonSettings', ['roles']),
    role_name() {
      return get(this.roles.filter((role) => role.id === this.currentUser.role_id)[0], 'name', '')
    },
  },
  mounted() {
    // merge objects => validation works
    this.form = { ...this.form, ...pick(this.currentUser, Object.keys(this.form)) }
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    ...mapActions('user', ['setUser']),
    async onUpdateInfo() {
      if (!this.isValidForm()) {
        return
      }
      await this.onSubmitForm()
    },
    async onSubmitForm() {
      const form = { ...this.form }
      if (isHttpLink(form.avatar)) {
        delete form.avatar
      }
      const { success, data } = await userService.updateInfo(form)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: data.includes('email') ? MESSAGES.ADMIN_MT.ADM07 : MESSAGES.COMMON.MSG15,
        })
        return
      }
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG01,
      })
      await this.reloadData(true)
    },
    setFormValue(name, value) {
      if (value === undefined) {
        return
      }
      this.form[name] = value
      const validateInstance = this.$v.form[name]
      validateInstance.$touch()
      this.setValidateErrorMessage(validateInstance, name)
    },
    setValidateErrorMessage(instance, name) {
      // check required
      if (!instance.required) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG08
        return
      } else {
        this.errorMessages.form[name] = ''
      }
      // check email
      this.checkEmail(instance, name)
    },
    status(validation) {
      return {
        invalid: validation.$dirty && validation.$invalid,
      }
    },
    isValidForm() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      if (!isValid) {
        const requiredFields = ['name', 'email']
        requiredFields.forEach((key) => {
          this.setValidateErrorMessage(this.$v.form[key], key)
        })
      }
      this.errorMessages.form.avatar = ''
      return isValid
    },
    formatter(e) {
      return String(e).substring(0, MAX_LENGTH.DEFAULT)
    },
    checkEmail(instance, name) {
      if (name === 'email') {
        if (!instance.email) {
          this.errorMessages.form[name] = MESSAGES.COMMON.MSG09
        }
        if (!instance.notFreeMail) {
          this.errorMessages.form[name] = MESSAGES.COMMON.MSG10
        }
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },
    loadFile(event) {
      const file = event.target.files[0]
      this.$refs['upload-image'].value = ''
      this.errorMessages.form.avatar = ''
      // validate file type
      if (!validateFileSize(file, MAX_FILE_SIZE.IMAGE)) {
        this.errorMessages.form.avatar = MESSAGES.COMMON.MSG99
        return
      }
      // validate file size
      if (!validateFileType(file, this.allowFileTypes)) {
        this.errorMessages.form.avatar = MESSAGES.COMMON.MSG23
        return
      }
      fileImageToBase64(file).then((result) => {
        this.form.avatar = result
      })
    },

    handleOnclickImage() {
      this.$refs[this.imageRef].click()
    },

    async reloadData(isMaster = false) {
      const user = await userService.get(isMaster)
      this.setUser({ ...this.currentUser, ...user })
      this.form = { ...this.form, ...user }
    },

    showChangePasswordDialog() {
      this.$bvModal.show(this.dialogChangePassId)
    },

    async changePassword(formData) {
      const { success, data } = await userService.changePassword(formData)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: data.old_password ? MESSAGES.ADMIN_MT.ADM08 : MESSAGES.COMMON.MSG15,
        })
        return
      }
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG01,
      })
      this.$bvModal.hide(this.dialogChangePassId)
    },
  },
}
</script>
